import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import "./style.css"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Seo title="Terms of use" />
      <div className="privacy-policy-body">
        <div className="title">
          <h2>PRIVACY NOTICE</h2>
          <p>Last updated: March 31, 2022</p>
        </div>
        <div className="intro">
          <p>
            This privacy notice for <b>nanoresume</b>, describes how and why we
            might collect, store, use, and/or share ("process") your information
            when you use our services ("Services"), such as when you: Visit our
            website at https://www.nanoresume.com, or any website of ours that
            links to this privacy notice Engage with us in other related ways,
            including any sales, marketing, or events Questions or concerns?
            Reading this privacy notice will help you understand your privacy
            rights and choices. If you do not agree with our policies and
            practices, please do not use our Services. If you still have any
            questions or concerns, please contact us at{" "}
            <b>contact@nanoresume.com</b>.
          </p>
        </div>
        <div className="personel-information">
          <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
          <h4>Personal information you disclose to us</h4>
          <p>
            <b>
              <i>In Short: </i>
            </b>
            We collect personal information that you provide to us.
          </p>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>

          <p>
            Personal Information Provided by You. The personal information that
            we collect depends on the context of your interactions with us and
            the Services, the choices you make, and the products and features
            you use. The personal information we collect may include the
            following:
          </p>
          <ul>
            <li>names</li>
            <li>email addresses</li>
            <li>passwords</li>
          </ul>

          <p>
            <b>Sensitive Information.</b> We do not process sensitive
            information.
          </p>

          <p>
            Social Media Login Data. We may provide you with the option to
            register with us using your existing social media account details,
            like your Facebook, Twitter, or other social media account. If you
            choose to register in this way, we will collect the information
            described in the section called "HOW DO WE HANDLE YOUR SOCIAL
            LOGINS?" below.
          </p>

          <p>
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>

          <h4>Information automatically collected</h4>

          <p>
            In Short: Some information — such as your Internet Protocol (IP)
            address and/or browser and device characteristics — is collected
            automatically when you visit our Services.
          </p>
          <p>
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </p>
          <p>
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </p>
          <p>The information we collect includes:</p>

          <ul>
            <li>
              Log and Usage Data. Log and usage data is service-related,
              diagnostic, usage, and performance information our servers
              automatically collect when you access or use our Services and
              which we record in log files. Depending on how you interact with
              us, this log data may include your IP address, device information,
              browser type, and settings and information about your activity in
              the Services (such as the date/time stamps associated with your
              usage, pages and files viewed, searches, and other actions you
              take such as which features you use), device event information
              (such as system activity, error reports (sometimes called "crash
              dumps"), and hardware settings).
            </li>
            <li>
              Device Data. We collect device data such as information about your
              computer, phone, tablet, or other device you use to access the
              Services. Depending on the device used, this device data may
              include information such as your IP address (or proxy server),
              device and application identification numbers, location, browser
              type, hardware model, Internet service provider and/or mobile
              carrier, operating system, and system configuration information.
            </li>
          </ul>
        </div>
        <div className="process-information">
          <h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
          <p>
            In Short: We process your information to provide, improve, and
            administer our Services, communicate with you, for security and
            fraud prevention, and to comply with law. We may also process your
            information for other purposes with your consent.
          </p>

          <p>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </p>

          <ul>
            <li>
              To facilitate account creation and authentication and otherwise
              manage user accounts. We may process your information so you can
              create and log in to your account, as well as keep your account in
              working order.
            </li>
            <li>
              To save or protect an individual's vital interest. We may process
              your information when necessary to save or protect an individual’s
              vital interest, such as to prevent harm.
            </li>
          </ul>
        </div>
        <div className="cookie-policy">
          <h3>3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
          <p>
            In Short: We may use cookies and other tracking technologies to
            collect and store your information.
          </p>
          <p>
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </p>
        </div>
        <div className="social-login">
          <h3>4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h3>
          <p>
            In Short: If you choose to register or log in to our services using
            a social media account, we may have access to certain information
            about you.
          </p>
          <p>
            Our Services offer you the ability to register and log in using your
            third-party social media account details (like your Facebook or
            Twitter logins). Where you choose to do this, we will receive
            certain profile information about you from your social media
            provider. The profile information we receive may vary depending on
            the social media provider concerned, but will often include your
            name, email address, friends list, and profile picture, as well as
            other information you choose to make public on such a social media
            platform.
          </p>
          <p>
            We will use the information we receive only for the purposes that
            are described in this privacy notice or that are otherwise made
            clear to you on the relevant Services. Please note that we do not
            control, and are not responsible for, other uses of your personal
            information by your third-party social media provider. We recommend
            that you review their privacy notice to understand how they collect,
            use and share your personal information, and how you can set your
            privacy preferences on their sites and apps.
          </p>
        </div>
        <div className="information-protection">
          <h3>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
          <p>
            In Short: We aim to protect your personal information through a
            system of organizational and technical security measures.
          </p>
          <p>
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </p>
        </div>
        <div className="privacy-right">
          <h3>6. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
          <p>
            In Short: In some regions, such as the European Economic Area (EEA),
            United Kingdom (UK), and Canada, you have rights that allow you
            greater access to and control over your personal information. You
            may review, change, or terminate your account at any time.
          </p>
          <p>
            In some regions (like the EEA, UK, and Canada), you have certain
            rights under applicable data protection laws. These may include the
            right (i) to request access and obtain a copy of your personal
            information, (ii) to request rectification or erasure; (iii) to
            restrict the processing of your personal information; and (iv) if
            applicable, to data portability. In certain circumstances, you may
            also have the right to object to the processing of your personal
            information. You can make such a request by contacting us by using
            the contact details provided in the section “HOW CAN YOU CONTACT US
            ABOUT THIS NOTICE?” below.
          </p>
          <p>
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </p>
          <p>
            If you are located in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your local data protection supervisory
            authority. You can find their contact details here:
            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
          </p>
          <p>
            If you are located in Switzerland, the contact details for the data
            protection authorities are available here:
            https://www.edoeb.admin.ch/edoeb/en/home.html.
          </p>
          <p>
            <b>Withdrawing your consent:</b> If we are relying on your consent
            to process your personal information, which may be express and/or
            implied consent depending on the applicable law, you have the right
            to withdraw your consent at any time. You can withdraw your consent
            at any time by contacting us by using the contact details provided
            in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
          </p>
          <p>
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal, nor when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>
          <p>
            <b>Opting out of marketing and promotional communications:</b> You
            can unsubscribe from our marketing and promotional communications at
            any time by Clicking the switch button to switch off marketing and
            promotional communications, or by contacting us using the details
            provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
            below. You will then be removed from the marketing lists. However,
            we may still communicate with you — for example, to send you
            service-related messages that are necessary for the administration
            and use of your account, to respond to service requests, or for
            other non-marketing purposes.
          </p>
          <h4>Account Information</h4>
          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <ul>
            <li>
              Log in to your account settings and update your user account.
            </li>
            <li>Contact us using the contact information provided.</li>
          </ul>
          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>
          <p>
            <b>Cookies and similar technologies:</b> Most Web browsers are set
            to accept cookies by default. If you prefer, you can usually choose
            to set your browser to remove cookies and to reject cookies. If you
            choose to remove cookies or reject cookies, this could affect
            certain features or services of our Services. To opt out of
            interest-based advertising by advertisers on our Services visit
            http://www.aboutads.info/choices/.
          </p>
        </div>
        <div className="minors-policy">
          <h3>7. DO WE COLLECT INFORMATION FROM MINORS?</h3>
          <p>
            <b>In Short:</b> We do not knowingly collect data from or market to
            children under 18 years of age.
          </p>
          <p>
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Services, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent’s use of the Services. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we may have collected from children under
            age 18, please contact us at contact@nanoresume.com
          </p>
        </div>
        <div className="update-privacy-policy">
          <h3>8. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
          <p>
            <b>In Short:</b> Yes, we will update this notice as necessary to
            stay compliant with relevant laws.
          </p>
          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
        </div>
        <div className="contact-policy">
          <h3>9. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
          <p>
            If you have questions or comments about this notice, you may email
            us at <b>contact@nanoresume.com</b>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
